import { useState, createContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import './style.css';
import API from '../../utils/API';
import Swal from "sweetalert2";

export const AppContext = createContext();

export const ContextProvider = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    const [userInfo, setUserInfo] = useState(null)
    const [buildingInfo, setBuildingInfo] = useState(null)
    const [building, setBuilding] = useState(null)
    const [fixed30DayData, setFixed30DayData] = useState(null)
    const [openSidebar, setOpenSidebar] = useState(false)
    const [mobile, setMobile] = useState(window.innerWidth <= 640);
    const [laptop, setLaptop] = useState(window.innerWidth <= 1225);
    const [nodeSelected, setNodeSelected] = useState({})
    const [gwUser, setGwUser] = useState(true);    
    const [versionInfo, setVersionInfo] = useState(null)
    const [faq, setFaq] = useState(null)
    const maintenance = false

    useEffect(() => {        
        setUser(JSON.parse(localStorage.getItem('gw_usr_dt_ls')))

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    const handleResize = () => {
        setMobile(window.innerWidth <= 640);
        setLaptop(window.innerWidth <= 1225);
    };

    useEffect(() => {
        
        if (maintenance){
            navigate("/unavailable")
            return
        }

        if (user && (!user.username.includes('greenwaveinnovations.ca') && !user.username.includes('c9'))){
            setGwUser(false)
        }
        if ([null, '', 'all'].includes(building) || !user) return
        const cancelToken = API.cancelToken();
        const auth = user.token;

        API.getBuilding(cancelToken, auth, building).then(res => {
            setBuildingInfo(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building, user, navigate])

    useEffect(() => {
        if (maintenance){
            navigate("/unavailable")
            return
        }
        
        if (location.pathname === '/') {
            navigate("/map")
        }
        const outsideLogin = ['/recover', '/login', '/passchange']
        const onlyGw = []

        if (outsideLogin.includes(location.pathname) || location.pathname.includes('/public')) return
        let tempUser = JSON.parse(localStorage.getItem('gw_usr_dt_ls'))
        if (!tempUser || !tempUser.username) {
            navigate("/login")
            return
        }

        if (user && onlyGw.includes(location.pathname) && !user.username.includes('greenwaveinnovations.ca') && !user.username.includes('c9')){
            navigate("/")
            return
        }

        const cancelToken = API.cancelToken();
        const auth = tempUser.token;
        API.getUser(cancelToken, auth).then(res => {
            setUserInfo(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });

        API.getVersion(cancelToken, auth).then(res => {
            setVersionInfo(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });

        API.getFaq(cancelToken, auth).then(res => {
            setFaq(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });
        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, location, navigate])

    const logout = () => {
        localStorage.removeItem('gw_usr_dt_ls');
        setUser(null)
    }

    const login = (user) => {
        setUser(user)
        localStorage.setItem('gw_usr_dt_ls', JSON.stringify(user))
    }

    const resetBuilding = () => {
        setBuilding(null)
        setBuildingInfo(null)
        setFixed30DayData(null)
    }

    return (
        <AppContext.Provider value={
            {
                getUser: user,
                getUserInfo: userInfo,
                login: login,
                logout: logout,
                setBuildingInfo: setBuildingInfo,
                setBuilding: setBuilding,
                isGwUser: gwUser,
                getBuilding: building,
                getBuildingInfo: buildingInfo,
                getFixed30DayData: fixed30DayData,
                setFixed30DayData: setFixed30DayData,
                resetBuilding: resetBuilding,
                getSidebar: openSidebar,
                setSidebar: setOpenSidebar,
                getMobile: mobile,
                getLaptop: laptop,
                getFaq: faq,
                versionInfo: versionInfo,
                nodeSelected: nodeSelected,
                setNodeSelected: setNodeSelected
            }
        }>
            {props && props.children}
        </AppContext.Provider>
    )
}

export default ContextProvider;