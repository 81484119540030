import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import AssetInfo from '../../components/AssetInfo';
import AssetMaintenance from '../../components/AssetMaintenance';
import moment from 'moment';
import GlobalFuncs from '../../utils/GlobalFuncs';
import Loader from '../../components/Loader';

const Circuit = () => {

    const location = useLocation();
    const [getPutPost, setGetPutPost] = useState()

    const [device, setDevice] = useState(null)
    const [channelName, setChannelName] = useState()
    const [tab, setTab] = useState("1")
    const [circuit, setCircuit] = useState()
    const [devicePhotos, setDevicePhotos] = useState(null)
    const [equipmentRuntime, setEquipmentRuntime] = useState([])
    const [maintenanceData, setMaintenanceData] = useState(null)
    const [groups, setGroups] = useState(null)
    
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (['put'].includes(getPutPost)) return
        if (queryParams.size !== 0) {
            let tempDevice = queryParams.get('device')
            let tempTab = queryParams.get('tab')
            let tempChannel = queryParams.get('circuit')
            if (!tempDevice) navigate('/map')
            if (!tempChannel) navigate(`/map`)
            setDevice(tempDevice)
            setChannelName(tempChannel)
            setGetPutPost('get')
            setTab(tempTab)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        if (!context.getUser || !device || !channelName) return
        const cancelToken = API.cancelToken();
        const auth = context.getUser.token;
        if (getPutPost === 'get' && device !== null) {
            setLoading(true)
            API.getCircuit(cancelToken, auth, device, channelName).then(res => {
                setCircuit(res.data)
                if (!res.data.virtual) {
                    API.getMaintenanceGroupByCircuit(cancelToken, auth, device, channelName).then(async res => {
                        let runtime = await API.getCircuitRuntime(cancelToken, auth, "2022-01-01", moment().format('YYYY-MM-DD'), device, channelName)
                        setEquipmentRuntime({ [channelName]: runtime.data.series })
                        setMaintenanceData(res.data)
                        setLoading(false)
                    }).catch(err => {
                        setLoading(false)
                        console.log(err)
                        if (err.message === 'cancelling') return
                        let message = 'Please try again later.'
                        if (err.response && err.response.data && 'error' in err.response.data) {
                            message = err.response.data.error
                        }
                        Swal.fire({
                            title: 'Error.',
                            text: message,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    })
                } else {
                    setEquipmentRuntime({})
                    setMaintenanceData(null)
                    setLoading(false)
                }
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && 'error' in err.response.data) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })

            API.getDevicePictures(cancelToken, auth, device, channelName).then(res => {
                setDevicePhotos(res.data)
            }).catch(err => {
                setLoading(false)
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && 'error' in err.response.data) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            })
        }

        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, device, channelName, context.getUser])

    useEffect(() => {
        if (equipmentRuntime.length === 0 || !maintenanceData) return
        updateNumbers(maintenanceData, equipmentRuntime)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [equipmentRuntime, maintenanceData])

    const updateNumbers = async (tempGroups) => {
        if (equipmentRuntime.length === 0) return
        let final = GlobalFuncs.getCalculatedMaintenanceValues(tempGroups, equipmentRuntime)
        setGroups(final)
    }

    return (
        <div>
            <div className="laptop:p-[24px] tablet:p-[16px] mobile:p-[12px] flex flex-nowrap justify-between items-center rounded-md bg-[#FFF] shadow-md" >
                <div className='flex flex-nowrap gap-6 text-base font-bold'>
                    <Link to={`/circuit?device=${device}&circuit=${encodeURIComponent(channelName)}&tab=1`} className={`${tab === "1" ? "active-reports-tab" : ""}`}>Asset Info</Link>
                    <Link to={`/circuit?device=${device}&circuit=${encodeURIComponent(channelName)}&tab=2`} className={`${tab === "2" ? "active-reports-tab" : ""}`}>Maintenance</Link>
                </div>
            </div>
            {
                loading &&
                <div className='h-screen'>
                    <Loader />
                </div>
            }
            {!loading && tab === "1" &&
                <AssetInfo
                    circuit={circuit}
                    setCircuit={setCircuit}
                    devicePhotos={devicePhotos}
                    setDevicePhotos={setDevicePhotos}
                    device={device}
                    setDevice={setDevice}
                    channelName={channelName}
                    setChannelName={setChannelName}
                    loading={loading}
                    setLoading={setLoading}
                    error={error}
                    setError={setError}
                    getPutPost={getPutPost}
                    setGetPutPost={setGetPutPost} />
            }

            {!loading && tab === "2" &&
                <AssetMaintenance
                    circuit={circuit}
                    channelName={channelName}
                    groups={groups}
                    setGroups={setGroups}
                    loading={loading}
                    setLoading={setLoading}
                    updateNumbers={updateNumbers}
                    error={error}
                    setError={setError} />
            }
        </div>
    )
}


export default Circuit;